/* light.css is loaded and applied for golfers with the light or auto theme,
 * but will get overriden for auto theme and matching the dark media query */

:root {
    --background: #fdfdfd;
    --color:      #343a40;
    --grey:       #85888c; /* #85888c is 0.6 * #343a40 + 0.4 * #ffffff */

    /* https://flatuicolors.com */
    --red:        #FC5C65BB;
    --orange:     #FD9644BB;
    --yellow:     #FED330BB;
    --green:      #26DE81BB;
    --blue:       #45AAF2BB;
    --purple:     #8C7AE6BB;

    --light-blue:   #def;
    --light-green:  #efd;
    --light-grey:   #eee;
    --light-yellow: #feb;

    --diff-light-green: #e6ffec;
    --diff-light-red:   #ffebe9;

    --link-color: #07b;

    --arg-background:     #eee;
    --asm-error:          red;
    --badge-background:   #343a40;
    --cheevo-not-earned:  #343a40;
    --red-text:           var(--red);
    --summary-background: #343a40;
    --summary-color:      #fdfdfd;

    --gl-border: #ccc;
    
    --rank-1-glow: none;
    --rank-2-glow: none;
    --rank-3-glow: none;

    --play-color: silver;
}

select {
    background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'><path d='M2 0L0 2h4zm0 5L0 3h4' fill='%23343A40'/></svg>") no-repeat right .5rem center/auto calc(100% - 1.5rem), var(--background);
}

select.selectActive {
    background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'><path d='M2 0L0 2h4zm0 5L0 3h4' fill='%23ebebeb'/></svg>") no-repeat right .5rem center/auto calc(100% - 1.5rem), var(--color);
    color: var(--background);
}

#hole-forsyth-edwards-notation :is(aside > div, pre, .readonly-output) {
    font-family: chess, monospace;
}
